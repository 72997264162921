import { useTranslation } from "react-i18next";

function Notfound() {
  const { t } = useTranslation();
  return (
    <main className="main">
      <h1>404</h1>
      <h2>{t("notfound")}</h2>
    </main>
  );
}

export default Notfound;
