import logo from "./logo.svg";

function Header() {
  return (
    <header>
      <img src={logo} className="logo" alt="Furuki Corporation" />
    </header>
  );
}

export default Header;
