import QrcodeSVG from "../qrcode.svg";

function Qrcode() {
  return (
    <main className="main">
      <img src={QrcodeSVG} className="qrcode" alt="Furuki Corporation" />
    </main>
  );
}

export default Qrcode;
