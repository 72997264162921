import "../App.css";
import {
  WhatsAppOutlined,
  GlobalOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

function Card() {
  const { t } = useTranslation();
  return (
    <>
      <main>
        <div className="name">{t("name")}</div>
        <div className="ocupation">{t("occupation")}</div>
        <div>
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="#67aef0" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="#92c5f6" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="#c6e3f9" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#e4f0fc" />
            </g>
          </svg>
        </div>
      </main>

      <footer className="cardFooter">
        <div>
          <a
            href="mailto:minori@furukicorp.com"
            target="_blank"
            rel="noreferrer"
          >
            <button className="email mx-auto ">
              <MailOutlined />
              <span className="sns">{t("email")}</span>
            </button>
          </a>

          <a
            href="https://wa.me/593985267560?text="
            target="_blank"
            rel="noreferrer"
          >
            <button className="whatsapp text-left">
              <WhatsAppOutlined />
              <span className="sns">WhatsApp</span>
            </button>
          </a>

          <a href="https://www.furukicorp.com" target="_blank" rel="noreferrer">
            <button className="website text-right">
              <GlobalOutlined />
              <span className="sns">{t("website")}</span>
            </button>
          </a>
        </div>
      </footer>
    </>
  );
}

export default Card;
